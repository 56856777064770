import { Component } from "react";
import { Link } from "react-router-dom";
import { formatPrice } from "../../../Utils";
import { SaticAssets } from "../../../Constants";
import StartRatings from "./StartRatings";

export default class ProductCardView extends Component{

    state = {
        loaded:false,
        CountValue:0
    }

    CountValueChange(id){
        const {cartItemCountByPid} = this.props;
        this.setState({CountValue:cartItemCountByPid(id)});
    }

    
    onChangeValue(id,value=null,increment=false,decrement=false){
        console.log(value+'okkk');
        const { 
            removeFromCart,
            addToCartById
    } = this.props;
    if(value=='' || value=='null' || value==null){
     value=0;
    }
      if(increment==true){
        addToCartById(id,null)
      }else if(decrement==true){
        removeFromCart(id, false)
      }else{
        addToCartById(id,value)
      }
        
        this.CountValueChange(id);
    }

    addToCart(d){
        const { 
            addCart
    } = this.props;
        addCart(d)
        this.CountValueChange(d.id);
    }

    componentDidMount() {
       this.CountValueChange(this.props.data.id);
      }


    onImgLoadError=(e)=>{
        e.target.src = SaticAssets.IMG_ERROR_PLACEHOLDER
        // this.setState({loaded:true})
    }

    render(){

        const cls = this.props.addClass || ''; 
        const loaded = this.state.loaded;
        const { inCart,
                addCart, 
                data, 
                isUserLoggedIn, 
                showPreview,
                isFav,
                addToFav,
                removeFromFav,
                addRatings,
                showToast,
                removeFromCart,
                cartItemCountByPid,
                addToCartById
        } = this.props;
        const d = data;

        if( inCart === undefined 
            || isUserLoggedIn===undefined 
            || !addCart 
            || !d 
            || !showPreview
            || isFav===undefined
            || !addToFav
            || !removeFromFav
            || !addRatings
            || !showToast
            || !removeFromCart
            || !cartItemCountByPid
            || !addToCartById
            )throw new Error("ProductCardView:props not supplied!")

        return (
            
               <div className="col-xl-4 col-md-6">
                <div className={`cards-md ${cls}`}>    
                {d?.image_web!='' && d?.image_web!=null?<div className="cards-md__img-wrapper">
                        <Link to={{pathname:"/product-detail/"+d?.id}}>
                            <img 
                                onLoad={()=>{this.setState({loaded:true})}} 
                                onError={(e)=>{this.onImgLoadError(e)}} 
                                src={d?.image_web} 
                                alt={d?.title} 
                                style={{width:"100%",
                                    height: "100%",
                                    objectFit: "contain"}}
                                
                            />
                        </Link>
                        {/*<span  className="tag danger font-body--md-400"  style={{display:(d.sp>70)?'inherit':'none'}}>*/}
                        <span  className="tag danger font-body--md-400"  style={{display:(d.sp>0)?'inherit':'none'}}>
                            Sale {d.sp}%
                        </span>
                        <span  className="tag danger font-body--md-400"  style={{display:(d.discount>0)?'inherit':'none',marginTop:(d.image_web=='' || d.image_web==null)?'30px':'0px'}}>
                            Off {d.discount}%
                        </span>
                        <div className="cards-md__favs-list">
                            <span className={"action-btn" + (isFav?" item-in-wishlist":"")} onClick={()=>{(!isFav)?addToFav(d):removeFromFav(d)}}>
                                <i className="fa fa-heart"></i>
                            </span>
                            <span className="action-btn" onClick={()=>{showPreview(d.id)}}>
                                <i className="fa fa-eye"></i>
                            </span>
                            
                        </div>
                    </div>:<></>}
                    <div className="cards-md__info d-flex justify-content-between align-items-center" style={{maxWidth:d?.image_web!='' || d?.image_web!=null?'100%':'57%'}}>
                        <div className="cards-md__info-left" style={{textAlign:'center'}}>
                        <Link to={{pathname:"/product-detail/"+d?.id}}>
                       <h6 className="font-body--md-400">{d?.title}</h6>
                       </Link>
                            {/* {isUserLoggedIn()
                                ?(  <div className="cards-md__info-price">
                                        <span className="font-body--lg-500">{formatPrice(d.price)} </span>
                                        <del className="font-body--lg-400">{formatPrice(d.mrp_price)}</del>
                                         {" / "+d.retailer_alternativ_unit}
                                    </div>)
                                :("")
                            } */}
                            
                            {/* <StartRatings 
                            showToast={showToast}
                            addRatings={addRatings}
                            pid={d.id} 
                            rating={d.totalRating}
                            /> */}
                        </div>

                        <div className="card-action-wrqpper">
                            <div className="counter-btn-wrapper products__content-action-item" style={{display:inCart?'':'none'}}>
                                <button onClick={()=>this.onChangeValue(d.id,0,false,true)} 
                                    className="counter-btn-dec counter-btn">
                                    -
                                </button>
                               
                                <input defaultValue={cartItemCountByPid(d.id)} value={this.state.CountValue} onChange={(e)=>{e.target.value==null || e.target.value=='null'?this.onChangeValue(d.id,0):this.onChangeValue(d.id,e.target.value);  e.target.value==null || e.target.value=='null'?this.setState({CountValue:0}):this.setState({CountValue:e.target.value})}} type="text" id="counter-btn-counter" className="counter-btn-counter" min="0" max="1000" placeholder="0" />
                                <button onClick={()=>this.onChangeValue(d.id,0,true,false)}
                                    className="counter-btn-inc counter-btn">
                                    +
                                </button>
                            </div>
                            {d?.image_web=='' || d?.image_web==null?<div>
                            <span  className="tag danger font-body--md-400"  style={{display:(d.sp>0)?'inherit':'none'}}>
                            Sale {d.sp}%
                        </span>
                        <span  className="tag danger font-body--md-400"  style={{display:(d.discount>0)?'inherit':'none',width:'80px'}}>
                            Off {d.discount}%
                        </span>
                        </div>:<></>}
                            
                            
                            <div onClick={()=>{this.addToCart(d); this.setState({CountValue:1})}} className={"cards-md__info-right" + (inCart?" item-in-cart":"")} style={{display:!inCart?'':'none'}}>
                            {isUserLoggedIn()
                                ?(  <div className="cards-md__info-price" style={{marginRight:"5px"}}>
                                        <span className="font-body--lg-500">{formatPrice(d.price)} </span>
                                        <del className="font-body--lg-400">{formatPrice(d.mrp_price)} </del>
                                         {" / "+d.retailer_alternativ_unit}
                                    </div>)
                                :("")
                            }
                                {/* <p className="text-black">Add to Cart</p> */}
                                <span className={"action-btn" + (inCart?" item-in-cart":"")} >
                                    <i className="fa fa-shopping-bag bags"></i>
                                </span>
                            </div>


                        </div>


                        
                    </div>
                    {/* <span className="product-cardview_preloader" style={{display:(loaded)?'none':'flex'}}>
                        <img style={{height:'50%',maxHeight:'100px',width:'60%',maxWidth:'260px'}} src={SaticAssets.LOADER_IMG_LINK} alt="preload"/>
                    </span> */}
                </div></div>)
    }
}