import { Component } from "react";
import { Link } from "react-router-dom";
import { SaticAssets } from "../../../Constants";

class CategoryItem extends Component{

    setErrorImg=(el)=>{
        el.src = SaticAssets.IMG_ERROR_PLACEHOLDER
    }

    render(){
        const {item} = this.props;
       return (
       <div className="col-lg-3 col-md-6 col-sm-12 pb-1" style={{padding:"14px"}}>
        <div className="card product-item border-0 mb-4">
            <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0" style={{height:"150px"}}>

    <img src={ item.image_web }   style={{width:"100%",
                                    height: "100%",
                                    objectFit: "contain"}} alt={ item.title } onError={e=>{this.setErrorImg(e.target)}} className="img-fluid w-100"  />
    </div>
    <div className="card-body border text-center p-0 pt-4 pb-3">
    <div className="card-body text-center" style={{height: "63px"}}>
        <h5 className="card-title">{ item.title }</h5>
        {/* <p className="card-text">{ item.title } of best quality product you may visit .</p> */}
           </div>
    <div className="card-body text-center">
        <Link to={item.sub_categories.length>0? "/subcategory/"+item.id:"/categoryproducts/"+item.id} data-parentid={ item.id } className="btn btn-primary">View Products</Link>
    </div>
    </div>
   
</div>
</div>
            
            // <Link to={"/categoryproducts/"+item.id} data-parentid={ item.id } className="cards-sm popular-categories__wrapper-item">
            //     <div className="cards-sm__img-wrapper">
            //         <img src={ item.image_web } alt={ item.title } onError={e=>{this.setErrorImg(e.target)}}/>
            //     </div>
            //     <h5 className="font-body--xl-500">{ item.title }</h5>
            // </Link>
        )
    }


    
}

export default CategoryItem